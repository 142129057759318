import { useContext } from "react";
import Image from "../../assets/TimeoutImg.png";
import { LoginContext } from "../../store/LoginContext";
import { useNavigate } from "react-router-dom";

function BlurredBox({ setIsSessionActive, setIsVisible }) {
  const navigate = useNavigate();
  const { logoutHandle } = useContext(LoginContext);

  return (
    <div
      style={{
        margin: 0,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative", // Ensure children are positioned
      }}
    >
      <div className="blur-background">
        <div className="myDiv">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={Image}
                alt="img"
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: "10px 0",
              }}
            >
              <div></div>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "35px",
                  fontWeight: 600,
                  lineHeight: "41.02px",
                  textAlign: "left",
                }}
              >
                Session Timeout
              </div>
              <button
                style={{
                  outline: "none",
                  border: "none",
                  background: "#FF8A00",
                  color:"#FFFFFF",
                  cursor: "pointer",
                  height:"33px",
                  borderRadius:"5px"

                }}
                onClick={() => {
                  window.location.reload(); 
                  // navigate("/dashboard");
                  // logoutHandle()
                  setIsVisible(true);
                  setIsSessionActive(true);
                }}
              >
                REFRESH              
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlurredBox;
