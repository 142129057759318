import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const LoginContext = createContext();



const LoginContextProvider = ({ children }) => {
  
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const authToken = sessionStorage.getItem("AuthToken");
    if (authToken) {
      setIsLoggedIn(true);
    }
  }, []); // Empty dependency array to run once on mount

  const loginHandle = (data,authToken) => {
    if (data === "success") {
      sessionStorage.setItem("AuthToken", JSON.stringify({ token: authToken })); // Store the token on successful login
      setIsLoggedIn(true);
    }
  };

  const logoutHandle = () => {
    sessionStorage.removeItem("AuthToken"); // Clear the token on logout
    
    setIsLoggedIn(false);
    navigate("/Login")
  };

  return (
    <LoginContext.Provider value={{ isLoggedIn, loginHandle, logoutHandle }}>
      {children}
    </LoginContext.Provider>
  );
};



export default LoginContextProvider;


