import { Button } from "@mui/material";
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

function LiveChargerlist(props) {
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    color: "white",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Card
        sx={{
          maxWidth: 345,
          margin: "auto",
          mt: 1,
          color: "white",
          background: expanded ? "#464646" : "#1e1e1e",
          height: expanded ? "auto" : "35px",
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          onClick={handleExpandClick}
          style={{
            width: "100%",
            background: expanded ? "#1e1e1e" : "#464646",
            height: "35px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              color: "#fff",
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
              fontSize: "10px",
              fontWeight: 500,
              lineHeight: "14px",
              textAlign: "left",
            }}
          >
            {props.busId.ChargerNumber}
          </Typography>

          <Box>
            <input 
            type="checkbox" 
            style={{ cursor: "pointer" }} 
            checked={props.selectedChargers.some(
              (val) => val.ChargerNumber === props.busId.ChargerNumber
            )}
            onChange={() => {
              const index = props.selectedChargers.findIndex(
                (val) => val === props.busId.ChargerNumber
              );
              if (index === -1) {
                const data = [...props.selectedChargers];
                data.push(props.busId.ChargerNumber);
                props.setSelectedChargers(data);
              } else {
                const data = [...props.selectedChargers];
                data.splice(index, 1);
                props.setSelectedChargers(data);
              }
            }}
            />
            <ExpandMore
              expand={expanded}
              // onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </Button>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <table style={{ fontSize: "10px", width: "100%" }}>
              <tr>
                <td style={{ textAlign: "left" }}>CPO Name</td>
                <td style={{ textAlign: "right" }}>{props.busId.CPO_name}</td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }}>Charger Name</td>
                <td style={{ textAlign: "right" }}>
                  {props.busId.Charger_name}
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }}>Status</td>
                <td style={{ textAlign: "right" }}>{props.busId.Status}</td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }}>Bus Charged</td>
                <td style={{ textAlign: "right" }}>
                  {props.busId.Latest_data.BusesCharged}
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }}>Energy Consumed</td>
                <td style={{ textAlign: "right" }}>
                  {Math.round(props.busId.Latest_data.EnergyConsumed * 100) /
                    100}{" "}
                  Kwh
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }}>Operational Hours</td>
                <td style={{ textAlign: "right" }}>
                  {Math.round(props.busId.Latest_data.OperationalHours * 100) /
                    100}{" "}
                  Hrs.
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left", color: "#ffbfbf" }}>
                  Total Buses Charged
                </td>
                <td style={{ textAlign: "right", color: "#ffbfbf" }}>
                  {props.busId.Total.BusesCharged}
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left", color: "#ffbfbf" }}>
                  Total Energy Consumed
                </td>
                <td style={{ textAlign: "right", color: "#ffbfbf" }}>
                  {Math.round(props.busId.Total.EnergyConsumed * 100) / 100} Kwh
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left", color: "#ffbfbf" }}>
                  Total Operational Hours
                </td>
                <td style={{ textAlign: "right", color: "#ffbfbf" }}>
                  {Math.round(props.busId.Total.OperationalHours * 100) / 100}{" "}
                  Hrs.
                </td>
              </tr>
            </table>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}

export default LiveChargerlist;
